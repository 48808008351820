<template>
  <div class="profile-questions">
    <div class="profile-question-nav">
      <div
        v-for="({ label, type },index) in navs"
        :key="`profile-question-nav-${index}`"
        :class="{active: curType === type}"
        class="profile-question-nav-item"
        @click="curType = type"
      >
        {{ label }}
      </div>
    </div>
  <div>

  <div class="main">
    <JoLoadMore
      ref="loadmore"
      :auto-load="false"
      @onRefresh="onRefresh"
      @onLoadMore="onLoadMore"
    >
      <QuestionCard
        v-for="question in dataList"
        :key="question.id"
        :question="question"
      />
    </JoLoadMore>
  </div>
    </div>
  </div>
</template>

<script>
  import QuestionCard from '@/page/question/components/QuestionCard.vue'
  import { mapState } from 'vuex'
  import { limit } from '@/api'

  export default {
    name: 'ProfileQuestionQuestions',
    components: {
      QuestionCard,
    },
    data () {
      return {
        curType: 'all',
        navs: [
          {
            type: 'all',
            label: this.$t('profile.question.questions.all'),
          },
          {
            type: 'invitation',
            label: this.$t('profile.question.questions.invitation'),
          },
          {
            type: 'reward',
            label: this.$t('profile.question.questions.reward'),
          },
          {
            type: 'other',
            label: this.$t('profile.question.questions.other'),
          },
        ],

        dataList: [],
      }
    },
    computed: {
      ...mapState({
        user: state => state.CURRENTUSER,
      }),
      after () {
        const len = this.dataList.length
        return len > 0 ? this.dataList[len - 1].id : 0
      },
    },
    watch: {
      curType () {
        this.onRefresh()
      },
    },
    mounted () {
      this.$refs.loadmore.beforeRefresh()
    },

    methods: {
      onRefresh () {
        const params = { limit: limit, after: 0, type: this.curType }
        this.$http
          .get(`/user/questions`, { params: params })
          .then(({ data = [] }) => {
            this.dataList = data
            this.$refs.loadmore.afterRefresh(data.length < limit)
          })

        
      },
      onLoadMore () {
        const params = { limit: limit, after: this.after, type: this.curType }
        this.$http
          .get(`/user/questions`, { params: params })
          .then(({ data = [] }) => {
            this.dataList = [...this.dataList, ...data]
            this.$refs.loadmore.afterLoadMore(data.length < limit)
          })
      },
      getData () {
        
      },
    },
  }
</script>
<style lang='less'>
.profile-groups .c-group-item {
  border-bottom: 1px solid #ededed
}
</style>
