<template>
  <div class="p-profile-collection-posts">
    <JoLoadMore
      ref="loadmore"
      :auto-load="false"
      @onRefresh="onRefresh"
      @onLoadMore="onLoadMore"
    >
      <ul>
        <li
          v-for="post in postList"
          :key="`collected-${post.id}`"
          class="post-item"
        >
          <GroupFeedCard
            :feed="post"
            :group="post.group"
            :need-del="true"
            @unCollect="handleCancelCollect"
          />
        </li>
      </ul>
    </JoLoadMore>
  </div>
</template>

<script>
  import * as api from '@/api/group'
  import { limit } from '@/api'
  import GroupFeedCard from '@/components/FeedCard/GroupFeedCard.vue'

  export default {
    name: 'ProfileCollectionPost',
    components: { GroupFeedCard },
    data () {
      return {
        postList: [],
      }
    },
    mounted () {
      this.$refs.loadmore.beforeRefresh()
    },
    methods: {
      onRefresh () {
        api.getCollectionPosts().then(({ data }) => {
          this.postList = data
          this.$refs.loadmore.afterRefresh(data.length < limit)
        })
      },
      onLoadMore () {
        const offset = this.postList.length
        api.getCollectionPosts({ offset }).then(({ data }) => {
          this.postList = [...this.postList, ...data]
          this.$refs.loadmore.afterLoadMore(data.length < limit)
        })
      },
      // 取消收藏时 从列表中移除
      handleCancelCollect (id) {
        const { postList } = this
        this.$set(this, 'postList', postList.filter(post => post.id !== id))
      },
    },
  }
</script>

<style lang="less" scoped>
    .p-profile-collection-posts {
        .post-item {
            margin-bottom: 10px;
        }
    }
</style>
