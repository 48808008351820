<template>
  <div class="profile-question">
    <HeadTop :go-back="cancel">
      <div slot="nav" class="head-top-tabs-nav">
        <RouterLink
          v-for="({label, path}, index) in types"
          :key="`profile-question-tab-${index}`"
          :to="path"
          class="head-top-tabs-nav-item"
          v-text="label"
        />
      </div>
    </HeadTop>
    <KeepAlive>
      <RouterView class="profile-question-content" />
    </KeepAlive>
  </div>
</template>

<script>
  import HeadTop from '@/components/HeadTop'

  export default {
    name: 'ProfileQuestion',
    components: {
      HeadTop,
    },
    data () {
      return {
        types: [
          {
            label: this.$t('profile.question.questions.name'),
            path: '/profile/question/questions',
          },
          {
            label: this.$t('profile.question.answers.name'),
            path: '/profile/question/answers',
          },
          {
            label: this.$t('profile.question.follows.name'),
            path: '/profile/question/follows',
          },
        ],
      }
    },
    methods: {
      cancel () {
        this.$router.push('/profile')
      },
    },
  }
</script>

<style lang='less'>
@profile-question-prefix: profile-question;

.@{profile-question-prefix} {
  &-nav {
    position: fixed;
    z-index: 100;
    top: 90px;
    padding-top: 0 !important;
    display: flex;
    align-items: center;
    height: 90px;
    width: 100%;
    line-height: 89px;
    border-bottom: 1px solid #ededed; /*no*/
    background-color: #fff;
    justify-content: center;
    &-item {
      padding: 0 10px;
      font-size: 28px;
      color: #999;
      border-bottom: 3px solid transparent;

      & + & {
        margin-left: 90px;
      }

      &.router-link-active,
      &.active {
        border-color: @primary;
        color: #333;
      }
    }
  }
  &-content {
    padding-top: 180px;
  }
  .head-top-tabs-nav-item {
    white-space: nowrap;
  }
  .head-top-tabs-nav-item + .head-top-tabs-nav-item {
    border-left-width: 1px;
  }
}
</style>
