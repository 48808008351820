<template>
  <JoLoadMore
    key="find-ner"
    ref="loadmore"
    @onRefresh="onRefresh"
    @onLoadMore="onLoadMore"
  >
    <UserItem
      v-for="user in users"
      :key="user.id"
      :user="user"
    />
  </JoLoadMore>
</template>

<script>
  import { mapState } from 'vuex'
  import { limit } from '@/api'
  import { findNearbyUser } from '@/api/user.js'
  import UserItem from '@/components/UserItem.vue'

  export default {
    name: 'FindNer',
    components: {
      UserItem,
    },
    data () {
      return {
        users: [],
        page: 1,
        isActive: false,
      }
    },
    computed: {
      ...mapState(['POSITION']),
      lat () {
        return this.POSITION.lat
      },
      lng () {
        return this.POSITION.lng
      },
    },
    activated () {
      this.$refs.loadmore.beforeRefresh()
    },
    methods: {
      onRefresh (callback) {
        this.page = 1
        findNearbyUser({ lat: this.lat, lng: this.lng, offset: 0, limit }, this.page).then(
          ({ data = [] }) => {
            this.users = data
            this.$refs.loadmore.afterRefresh(data.length < limit)
          },
        )
      },
      onLoadMore (callback) {
        const { users } = this
        findNearbyUser({ lat: this.lat, lng: this.lng, offset: users.length, limit }, this.page).then(
          ({ data = [] }) => {
            this.users = [...this.users, ...data]
            this.$refs.loadmore.afterLoadMore(data.length < this.limit)
          },
        )
      },
    },
  }
</script>
