import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export default {
  state: {
    ROOMS: {},
    MESSAGES: {},
  },
  getters,
  actions,
  mutations,
}
