import i18n from '@/i18n'

/**
 * 钱包模块
 */
const Wallet = () =>
  import(/* webpackChunkName: 'wallet' */ '@/page/wallet/Wallet.vue')
const WalletRecharge = () =>
  import(/* webpackChunkName: 'wallet' */ '@/page/wallet/WalletRecharge.vue')
const WalletWithdraw = () =>
  import(/* webpackChunkName: 'wallet' */ '@/page/wallet/WalletWithdraw.vue')
const WalletWithdrawDetail = () =>
  import(/* webpackChunkName: 'wallet' */ '@/page/wallet/WalletWithdrawDetail.vue')
const WalletWithdrawInfo = () =>
  import(/* webpackChunkName: 'wallet' */ '@/page/wallet/WalletWithdrawInfo.vue')
const WalletDetail = () =>
  import(/* webpackChunkName: 'wallet' */ '@/page/wallet/WalletDetail.vue')
const WalletInfo = () =>
  import(/* webpackChunkName: 'wallet' */ '@/page/wallet/WalletInfo.vue')

export default [
  {
    path: '/wallet',
    component: Wallet,
    meta: {
      title: i18n.t('wallet.name'),
      requiresAuth: true,
    },
  },
  {
    path: '/wallet/recharge',
    component: WalletRecharge,
    meta: {
      title: i18n.t('wallet.recharge.name'),
    },
  },
  {
    path: '/wallet/withdraw',
    component: WalletWithdraw,
    meta: {
      title: i18n.t('wallet.withdraw.name'),
    },
  },
  {
    path: '/wallet/withdraw/detail',
    component: WalletWithdrawDetail,
    meta: {
      title: i18n.t('wallet.withdraw.detail'),
    },
  },
  {
    path: '/wallet/withdraw/detail/:id',
    component: WalletWithdrawInfo,
    meta: {
      title: i18n.t('wallet.withdraw.detail'),
    },
  },
  {
    path: '/wallet/detail',
    component: WalletDetail,
    meta: {
      title: i18n.t('wallet.withdraw.detail'),
    },
  },
  {
    path: '/wallet/detail/:id',
    component: WalletInfo,
    meta: {
      title: i18n.t('wallet.withdraw.detail'),
    },
  },
]
