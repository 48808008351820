import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import * as api from '../api'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
  strict: process.env.NODE_ENV !== 'production',
  mode: process.env.VUE_APP_ROUTER_MODE || 'hash',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    if (savedPosition) {
      // hack 滚动到保存的位置， 原生方法失效的问题
      setTimeout(() => {
        window.scrollTo(savedPosition.x, savedPosition.y)
      }, 100)
    } else {
      const {
        meta: { keepAlive = false, toTop = false },
      } = from
      if (keepAlive && !toTop) {
        from.meta.savedPosition =
          document.body.scrollTop || document.documentElement.scrollTop
      }
      setTimeout(() => {
        window.scrollTo(0, to.meta.savedPosition)
      }, 100)
    }
  },
})

/**
 * 路由守卫 登录检测 islogin
 *
 * 需要登录的页面路由需要添加
 * 登录后不可访问的路由需要添加
 * meta.forGuest = true
 *
 */
router.beforeEach((to, from, next) => {
  const logged = !!window.$lstore.hasData('H5_ACCESS_TOKEN')
  const forGuest = to.matched.some(record => record.meta.forGuest)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const SHOP_PLUS_ID = process.env.VUE_APP_SHOP_PLUS_ID || 0

  if (logged) {
    const redirect = getUrlKey('redirect')
    if (redirect && /^((http|https)?:\/\/)/.test(redirect)) {
      api.getShop(SHOP_PLUS_ID).then(({ data }) => {
        let params = data.params || []
        const url = data.url || ''
        params.link = redirect
        params = Object.keys(params).map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&')
        location.href = url + '/Home/Api/check?' + params
      })
    } else {
      forGuest ? next({ path: '/feeds?type=hot' }) : next()
    }
  } else {
    forGuest
      ? next()
      : requiresAuth
        ? next({ path: '/signin', query: { redirect: to.fullPath } })
        : next()
  }
})

export function getUrlKey (name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || ['', ''])[1].replace(/\+/g, '%20')) || null
}

export default router
