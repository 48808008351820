import { render, staticRenderFns } from "./FormTagsItem.vue?vue&type=template&id=6621f188&scoped=true&"
import script from "./FormTagsItem.vue?vue&type=script&lang=js&"
export * from "./FormTagsItem.vue?vue&type=script&lang=js&"
import style0 from "./FormTagsItem.vue?vue&type=style&index=0&id=6621f188&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6621f188",
  null
  
)

export default component.exports