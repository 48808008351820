<template>
  <div class="p-profile-draft">
    <CommonHeader>{{ $t('profile.draft.name') }}</CommonHeader>

    <main>
      <div class="m-sub-nav m-main">
        <RouterLink
          replace
          exact
          tag="div"
          exact-active-class="active"
          to="/profile/draft/questions"
          class="m-sub-nav-item"
        >
          <a>{{ $t('profile.draft.questions.name') }}</a>
        </RouterLink>
        <RouterLink
          replace
          exact
          tag="div"
          exact-active-class="active"
          to="/profile/draft/group_posts"
          class="m-sub-nav-item"
        >
          <a>{{ $t('profile.draft.group_posts.name') }}</a>
        </RouterLink>
      </div>
      <JoLoadMore
        ref="loadmore"
        class="main-container"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <div v-for="item in list" :key="item.id" class="draft-list">
          <RouterLink :to="toLink(item)" tag="div">
            <h3 class="title">{{ item.title }}</h3>

            <div class="bottom">
              <span>{{ item.created_at | time2tips }}</span>

              <div class="m-box m-justify-end m-flex-grow1 m-flex-shrink1">
                <span class="m-box m-aln-center" @click.prevent="handleMore(item)">
                  <svg class="m-style-svg m-svg-def">
                    <use xlink:href="#icon-more" />
                  </svg>
                </span>
              </div>
            </div>
          </RouterLink>
        </div>
      </JoLoadMore>
    </main>
  </div>
</template>
<script>
  import { limit } from '@/api'
  import * as api from '@/api/user'
  import NewsCard from '@/page/news/components/NewsCard'

  export default {
    name: 'ProfileDraft',
    components: {
      NewsCard,
    },
    data () {
      return {
        questions: [],
        group_posts: []
      }
    },
    computed: {
      list: {
        get () {
          return this.$data[this.type]
        },
        set (val) {
          this.$data[this.type] = val
        },
      },
      type () {
        return this.$route.params.type || 'questions'
      },
    },
    watch: {
      type (val) {
        this.$refs.loadmore.beforeRefresh()
      },
    },
    methods: {
      onRefresh () {
        api.drafts(this.type, {limit}).then(({ data }) => {
          this.list = data
          this.$refs.loadmore.afterRefresh(data.length < limit)
        })
      },
      onLoadMore () {
        const last = this.list.slice(-1)[0] || {}
        const params = { after: last.id, limit }
        api.drafts(this.type, params).then(({ data = [] }) => {
          this.list.push(...data)
          this.$refs.loadmore.afterLoadMore(data.length < limit)
        })
      },
      toLink (draft) {
        if (draft.type == 'questions') {
          return `/post/question?draftId=${draft.id}`
        }
        if (draft.type == 'group_posts') {
          return `/groups/create_post?draftId=${draft.id}`
        }
      },
      handleMore (item) {
        const actions = []
          actions.push({
            text: '编辑',
            method: () => {
              this.$router.push(this.toLink(item))
            },
          })
          actions.push({
            text: '删除',
            method: () => {
              setTimeout(() => {
                const actionSheet = [
                  {
                    text: this.$t('delete.name'),
                    style: { color: '#f4504d' },
                    method: () => {
                      api.draftDelete(this.type, item.id).then(() => {
                        this.$Message.success(this.$t('delete.success'))
                        this.$nextTick(() => {
                          this.onRefresh()
                        })
                      })
                    },
                  },
                ]
                this.$bus.$emit('actionSheet', actionSheet, this.$t('cancel'), this.$t('delete.confirm'))
              }, 200)
            },
          })
        

        this.$bus.$emit('actionSheet', actions)
      }
    },
  }
</script>
<style lang="less" scoped>
.p-profile-draft {
  .m-sub-nav {
    position: fixed;
    top: 90px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    z-index: 2;
    border-bottom: 1px solid @border-color;/*no*/

    .m-sub-nav-item {
      height: 100%;
      line-height: 90px;
      text-align: center;

      &.router-link-active {
        color: #333;
        border-bottom: 4px solid @primary;
      }
    }
  }

  .main-container {
    margin-top: 90px;

    .draft-list {
      background: #fff;
      padding: 30px;
      margin-bottom: 2px;

      h3 {
        margin: 0;
        margin-bottom: 30px;
        font-size: 36px;
        font-weight: normal;
        font-stretch: normal;
        color: #333;
        line-height: 1.4;
      }

      .bottom {
        width: 100%;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0;
        color: #999999;
        display: flex;
      }
    }
  }
}
</style>
