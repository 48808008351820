<template>
  <div class="profile-groups">
    <div class="profile-group-nav">
      <div
        v-for="({ label, type },index) in navs"
        :key="`profile-group-nav-${index}`"
        :class="{active: curType === type}"
        class="profile-group-nav-item"
        @click="curType = type"
      >
        {{ label }}
      </div>
    </div>
    <div>
      <GroupItem
        v-for="group in dataList"
        :key="`profile-group-${group.id}`"
        v-if="group.id"
        :role="true"
        :group="group"
      />
    </div>
  </div>
</template>

<script>
  import GroupItem from '@/page/group/components/GroupItem.vue'

  export default {
    name: 'ProfileGroupGroups',
    components: {
      GroupItem,
    },
    data () {
      return {
        curType: 'join',
        navs: [
          {
            type: 'join',
            label: this.$t('profile.group.groups.joined'),
          },
          {
            type: 'audit',
            label: this.$t('profile.group.groups.audit'),
          },
        ],

        dataList: [],
      }
    },
    watch: {
      curType () {
        this.getData()
      },
    },
    created () {
      this.getData()
    },

    methods: {
      getData () {
        this.$http
          .get(`/plus-group/user-groups?type=${this.curType}`)
          .then(({ data = [] }) => {
            this.dataList = data
          })
      },
    },
  }
</script>
<style lang='less'>
.profile-groups .c-group-item {
  border-bottom: 1px solid #ededed
}
</style>
