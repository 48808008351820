import FormLocationItem from './FormLocationItem.vue'
import FormInputItem from './FormInputItem.vue'
import FormAvatarItem from './FormAvatarItem.vue'
import FormTagsItem from './FormTagsItem.vue'
import FormSelectItem from './FormSelectItem.vue'
import FormSwitchItem from './FormSwitchItem.vue'

export default [
  FormInputItem,
  FormLocationItem,
  FormAvatarItem,
  FormTagsItem,
  FormSelectItem,
  FormSwitchItem,
]
