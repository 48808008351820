<template>
  <div class="profile-group-posts">
    <div class="profile-group-nav">
      <div
        v-for="({ label, type },index) in navs"
        :key="`profile-group-nav-${index}`"
        :class="{active: curType === type}"
        class="profile-group-nav-item"
        @click="curType = type"
      >
        {{ label }}
      </div>
    </div>
    <div>
      <GroupFeedCard
        v-for="post in dataList"
        :key="post.id"
        :feed="post"
        :group="post.group"
        :need-del="true"
      />
    </div>
  </div>
</template>
<script>
  import GroupFeedCard from '@/components/FeedCard/GroupFeedCard.vue'

  export default {
    name: 'ProfileGroupPosts',
    components: { GroupFeedCard },
    data () {
      return {
        curType: 1,
        navs: [
          {
            type: 1,
            label: this.$t('profile.group.posts.published'),
          },
          {
            type: 2,
            label: this.$t('profile.group.posts.toped'),
          },
          {
            type: 3,
            label: this.$t('profile.group.posts.topaudit'),
          },
        ],
        dataList: [],
      }
    },
    watch: {
      curType () {
        this.getData()
      },
    },
    created () {
      this.getData()
    },

    methods: {
      getData () {
        this.$http
          .get(`/plus-group/user-group-posts?type=${this.curType}`)
          .then(({ data = [] }) => {
            this.dataList = data
          })
      },
    },
  }
</script>
<style lang='less'>
.profile-group-posts{
  div.c-feed-card  + div.c-feed-card {
    margin-top: 10px;
  }
}
</style>
