import Vue from 'vue'
import Main from './main.vue'

const MessageConstructor = Vue.extend(Main)

let instance
const instances = []
let seed = 1

const hasOwn = (obj, key) => {
  return hasOwnProperty.call(obj, key)
}

const isVNode = node =>
  node !== null && typeof node === 'object' && hasOwn(node, 'componentOptions')

const Message = options => {
  options = options || {}

  if (typeof options === 'string') {
    options = {
      message: options,
    }
  }

  const userOnClose = options.onClose

  const id = 'message_' + seed++

  options.onClose = function () {
    Message.close(id, userOnClose)
  }

  instance = new MessageConstructor({
    data: options,
  })

  instance.id = id

  if (isVNode(instance.message)) {
    instance.$slots.default = [instance.message]
    instance.message = null
  }

  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.vm.visible = true
  instance.dom = instance.vm.$el
  instance.dom.style.zIndex = 1000 + seed
  instances.push(instance)
  return instance.vm
};

['success', 'warning', 'info', 'error'].forEach(type => {
  Message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
      }
    }
    options.type = type
    return Message(options)
  }
})

Message.close = function (id, userOnClose) {
  for (let i = 0, len = instances.length; i < len; i++) {
    if (id === instances[i].id) {
      typeof userOnClose === 'function' && userOnClose(instances[i])
      instances.splice(i, 1)
      break
    }
  }
}

Message.closeAll = function () {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close()
  }
}

export default Message
