<template>
  <div
    class="p-question-answer-card"
    @click="viewDetail"
  >
    <div class="main">
      <div
        v-if="!noUser"
        class="avatar"
        @click.stop="viewUser"
      >
        <Avatar
          :anonymity="anonymity"
          :user="user"
        />
      </div>
      <div
        class="info"
        :class="{'no-user': noUser}"
      >
        <h3
          v-if="!noUser"
          class="main-header"
        >
          <span class="name">
            <template
              v-if="!anonymity"
              @click.stop="viewUser"
            >
              {{ user.name }}
            </template>
            <template v-else-if="!isMine">{{ $t('profile.anonymity') }}</template>
            <template v-else>{{ user.name }} <span class="gray">({{ $t('anonymity') }})</span></template>
            <span
              v-if="answer.adoption"
              class="adoption"
            >
              {{ $t('question.answer.adoption')[1] }}
            </span>
            <span
              v-if="answer.invited"
              class="invited"
            >
              {{ $t('question.answer.invited') }}
            </span>
          </span>
          <span class="time">{{ answer.created_at | time2tips }}</span>
        </h3>
        <div
          v-if="answer.could !== false"
          class="main-body"
          v-html="body"
        />
        <div
          v-else
          class="main-body needPay"
        />
      </div>
    </div>

    <div
      class="main-button"
      :class="{'no-user': noUser}"
    >
      <div class="left">
        <span @click.stop="handleLikeTarget">
          <svg class="m-style-svg m-svg-small">
            <use :xlink:href="answer.liked ? '#icon-like' : '#icon-unlike'" />
          </svg>
          <span
            class="count"
            :class="{liked: answer.liked}"
          >
            {{ answer.likes_count | formatNum }}
          </span>
        </span>
        <span>
          <svg class="m-style-svg m-svg-small">
            <use xlink:href="#icon-comment" />
          </svg>
          <span class="count"> {{ answer.comments_count | formatNum }}</span>
        </span>
      </div>
      <div class="right">
        <span
          v-if="noUser"
          class="time"
        >
          {{ answer.created_at | time2tips }}
        </span>
        <span
          v-if="allowAdopt"
          class="adopt"
          @click.capture.stop="onAdopt"
        >
          <svg class="m-style-svg m-svg-small">
            <use xlink:href="#icon-adopt-answerr" />
          </svg>&nbsp;采纳
        </span>
        <span v-if="answer.onlookers_count">{{ answer.onlookers_count | t('question.answer.look.count') }}</span>
        <span
          v-if="answer.could"
          class="looked"
        >
          {{ $t('question.answer.look.already') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import * as api from '@/api/question/answer'

  export default {
    name: 'QuestionAnswerCard',
    props: {
      answer: { type: Object, required: true },
      allowAdopt: { type: Boolean, default: false },
      noUser: { type: Boolean, default: false },
    },
    data: () => ({
      likeTargetHanding: false,
    }),
    computed: {
      isInvited () {
        return this.answer.invited
      },
      onlookersAmount () {
        return this.$store.state.CONFIG['Q&A'].onlookers_amount || 0
      },
      anonymity () {
        const { anonymity } = this.answer
        return !!anonymity
      },
      user () {
        const { user = {} } = this.answer
        return user
      },
      body () {
        const body = this.answer.body || ''
        return body.replace(/@!\[[\u4e00-\u9fa5_a-zA-Z0-9]+]\(\d+\)/g, `[${this.$t('image')}]`)
      },
      isMine () {
        return this.user.id === this.$store.state.CURRENTUSER.id
      },
    },
    methods: {
      handleLike () {
        this.likeTargetHanding = true
        api.like(this.answer.id)
          .then(() => {
            this.likeTargetHanding = false
            this.answer.liked = true
            this.answer.likes_count += 1
          })
          .catch(({ response: { data } = {} }) => {
            this.likeTargetHanding = false
            this.$Message.error(data)
          })
      },
      handleUnlike () {
        this.likeTargetHanding = true
        api.unlike(this.answer.id)
          .then(() => {
            this.likeTargetHanding = false
            this.answer.liked = false
            this.answer.likes_count -= 1
          })
          .catch(({ response: { data } = {} }) => {
            this.likeTargetHanding = false
            this.$Message.error(data)
          })
      },
      handleLikeTarget () {
        if (this.likeTargetHanding) {
          return
        } else if (this.answer.liked) {
          this.handleUnlike()
          return
        }
        this.handleLike()
      },
      viewDetail () {
        if (!this.isInvited || this.answer.could) {
          return this.$router.push({
            name: 'AnswerDetail',
            params: { questionId: this.answer.question_id, answerId: this.answer.id },
          })
        }
        this.$bus.$emit('payfor', {
          title: this.$t('question.answer.pay.look'),
          content: this.$t('question.answer.pay.count', [this.onlookersAmount]),
          confirmText: this.$t('question.answer.pay.name'),
          amount: this.onlookersAmount,
          onOk: this.onlookers,
          checkPassword: true,
        })
      },
      onlookers (password) {
        api.onlookersAnswer(this.answer.id, password)
          .then(({ data }) => {
            const { answer } = data
            this.$emit('update:answer', answer)
            this.$Message.success(this.$t('question.answer.pay.success'))
          })
          .catch(({ response }) => {
            this.$Message.error(response.data.message)
          })
      },
      onAdopt () {
        api.adoptionAnswer(this.answer.question_id, this.answer.id)
          .then(() => {
            this.$Message.success('采纳成功！')
            this.$emit('update:answer', { ...this.answer, adoption: 1 })
          })
      },
      viewUser () {
        if (!this.user.id) return
        this.$router.push(`/users/${this.user.id}`)
      },
    },
  }
</script>

<style lang="less" scoped>
@avatar-size: 112px;

.p-question-answer-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  margin-bottom: 9px;

  .main {
    display: flex;
    width: 100%;
    padding: 30px 30px 20px 0;

    .avatar {
      width: @avatar-size;
      min-width: @avatar-size;
      max-width: @avatar-size;
      flex: none;
      text-align: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      width: 100%;

      &.no-user {
        padding-left: 24px;
      }

      .main-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        color: #333;
        margin-top: 0;
        margin-bottom: 29px;

        .name {
          display: flex;
          align-items: center;
          color: #666;

          span {
            border: 1px solid currentColor; /* no */
            border-radius: 8px;
            font-size: 22px;
            padding: 0 4px;
            margin-left: 12px;

            &.invited {
              color: @primary;
            }

            &.adoption {
              color: @success;
            }
          }
        }

        .time {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          color: #ccc;
        }
      }

      .main-body {
        text-align: initial;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        color: #666;
        margin-bottom: 28px;
        line-height: 1.4;

        &.needPay::after {
          content: " 该答案需要付费才能围观 need paid 该答案需要付费才能围观 need paid 该答案需要付费才能围观 need paid";
          text-shadow: 0 0 10px @text-color2; /* no */
          color: rgba(255, 255, 255, 0);
          margin-left: 5px;
          // filter: DXImageTransform.Microsoft.Blur(pixelradius=2);
          zoom: 1;
        }
      }
    }
  }

  .main-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: solid 1px #ededed; /* no */
    padding: 24px;
    padding-left: @avatar-size;

    &.no-user {
      padding-left: 24px;
    }

    .left {
      .count {
        margin-right: 30px;
        font-size: 28px;
        color: #b3b3b3;
      }

      .liked {
        color: @error;
      }
    }

    .right {
      font-size: 24px;
      color: @text-color3;

      .adopt {
        display: flex;
        align-items: center;
        border: 1px solid @primary;
        padding: 4px 10px;
        border-radius: 8px;
        color: @primary;
      }

      .looked {
        padding: 4px 16px;
        margin-left: 16px;
        border-radius: 6px;
        background-color: @text-color4;
        color: #fff;
      }
    }
  }
}

.gray {
  color: #ccc;
}
</style>
