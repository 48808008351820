import i18n from '@/i18n'

/**
 * dynamic import
 */
const QuestionBase = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionBase.vue')
const QuestionEdit = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionEdit.vue')
const QuestionList = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionList.vue')
const QuestionDetail = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionDetail.vue')
const QuestionReward = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionReward.vue')
const QuestionTopicList = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionTopicList.vue')
const QuestionTopicDetail = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionTopicDetail.vue')
const QuestionTopicExperts = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionTopicExperts.vue')
const QuestionTopicApply = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionTopicApply.vue')
const QuestionComments = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionComments.vue')
const AnswerDetail = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/AnswerDetail.vue')
const AnswerAdd = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/AnswerAdd.vue')
const QuestionSearch = () =>
  import(/* webpackChunkName:'question' */ '@/page/question/QuestionSearch.vue')
const ArticleLikeList = () =>
  import(/* webpackChunkName:'question' */ '@/page/article/ArticleLikeList.vue')
const ArticleRewardList = () =>
  import(/* webpackChunkName:'question' */ '@/page/article/ArticleRewardList.vue')

export default [
  {
    path: '/question',
    component: QuestionBase,
    meta: {
      requiresAuth: true,
      keepAlive: true,
    },
    children: [
      {
        name: 'question',
        path: '',
        component: QuestionList,
        meta: {
          keepAlive: true,
          title: i18n.t('question.name'),
        },
      },
      {
        path: 'topics',
        component: QuestionTopicList,
        meta: {
          title: i18n.t('question.topic.name'),
        },
      },
    ],
  },
  {
    path: '/questions/:questionId(\\d+)/',
    name: 'QuestionDetail',
    component: QuestionDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/questions/:questionId(\\d+)/edit',
    name: 'QuestionEdit',
    component: QuestionEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/questions/:questionId(\\d+)/reward',
    name: 'QuestionReward',
    component: QuestionReward,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/question/:questionId(\\d+)/comments',
    name: 'QuestionComments',
    component: QuestionComments,
    meta: {
      title: i18n.t('comment.name'),
    },
  },
  {
    path: '/question-topics/:topicId(\\d+)',
    name: 'QuestionTopicDetail',
    component: QuestionTopicDetail,
    meta: {
      requiresAuth: true,
      keepAlive: false,
    },
  },
  {
    path: '/question-topics/:topicId(\\d+)/experts',
    name: 'QuestionTopicExperts',
    component: QuestionTopicExperts,
    meta: {
      title: i18n.t('question.topic.expert'),
      requiresAuth: true,
      keepAlive: true,
    },
  },
  {
    path: '/questions/:questionId(\\d+)/answers/:answerId(\\d+)',
    name: 'AnswerDetail',
    component: AnswerDetail,
  },
  {
    path: '/question/:questionId(\\d+)/answers/add',
    name: 'AnswerAdd',
    component: AnswerAdd,
    meta: {
      title: i18n.t('question.answer.add'),
    },
  },
  {
    path: '/question/search',
    name: 'QuestionSearch',
    component: QuestionSearch,
    meta: {
      title: i18n.t('search'),
      keepAlive: false,
    },
  },
  {
    path: '/question/topic/apply',
    name: 'QuestionTopicApply',
    component: QuestionTopicApply,
    meta: {
      title: i18n.t('question.topic.apply.name'),
    },
  },
  /**
   * 点赞列表 && 打赏列表 路由格式固定
   * 帖子/资讯/问答 相关路由 统一使用 article 代替 id
   * 通过传递 不同的 meta[type] 实现组件复用
   * copy from @/routers/feed.js
   */
  {
    path: '/questions/:questionId(\\d+)/answers/:article(\\d+)/likers',
    component: ArticleLikeList,
    meta: {
      title: i18n.t('article.list.like'),
      type: 'answer',
    },
  },
  {
    path: '/questions/:questionId(\\d+)/answers/:article(\\d+)/rewarders',
    component: ArticleRewardList,
    meta: {
      title: i18n.t('article.list.reward'),
      type: 'answer',
    },
  },
]
