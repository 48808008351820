import api from '../api'

/**
 * Query questions list.
 * @author Seven Du <shiweidu@outlook.com>
 * @export
 * @param {Object} params
 * @param {string} params.type default: 'new', options: 'all', 'new', 'hot', 'reward', 'excellent'
 * @param {number} params.limit
 * @param {number} params.offset
 * @param {string} params.subject search keyword
 * @returns
 */
export function queryList (params = {}) {
  return api.get('/questions', {
    params,
    validateStatus: status => status === 200,
  })
}

/**
 * All questions.
 *
 * @param {string} type
 * @param {number} offset
 * @param {number} limit
 * @return {Promise}
 * @author Seven Du <shiweidu@outlook.com>
 */
export function list (type, offset = 0, limit = 15) {
  return queryList({ type, limit, offset })
}

/**
 * show a question.
 *
 * @param {number} id
 * @return {Promise}
 * @author Seven Du <shiweidu@outlook.com>
 */
export function show (id) {
  return api.get(`/questions/${id}`, {
    validateStatus: status => status === 200,
  })
}

/**
 * delete a question.
 *
 * @param {number} id
 * @return {Promise}
 * @author Seven Du <shiweidu@outlook.com>
 */
export function deleteQuestion (id) {
  return api.delete(`/currency-questions/${id}`, {
    validateStatus: status => status === 204,
  })
}

/**
 * 申请精选问答
 * @param {number} id
 * @returns
 */
export function applyQuestion (id, data) {
  return api.post(`/user/currency-question-application/${id}`,
    { ...data },
    { validateStatus: status => status === 201 }
  )
}

/**
 * Watch a question.
 *
 * @param {number} id
 * @return {Promise}
 * @author Seven Du <shiweidu@outlook.com>
 */
export function watch (id) {
  return api.put(
    `/user/question-watches/${id}`,
    {},
    {
      validateStatus: status => status === 204,
    },
  )
}

/**
 * unwatch a question.
 *
 * @param {number} id
 * @return {Promise}
 * @author Seven Du <shiweidu@outlook.com>
 */
export function unwatch (id) {
  return api.delete(`/user/question-watches/${id}`, {
    validateStatus: status => status === 204,
  })
}

/**
 * 发布问题
 *
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {Object} params
 * @param {string} params.subject
 * @param {string} params.title
 * @param {string} params.body
 * @param {string} params.text_body
 * @param {boolean} [params.anonymity=0]
 * @param {string} [params.password]
 * @returns
 */
export function postQuestion (params) {
  return api.post('/questions', params, { validateStatus: s => s === 201 })
}

export function editQuestion (id, params) {
  return api.patch(`/currency-questions/${id}`, { ...params } , { validateStatus: s => s === 204 })
}

/**
 * 获取话题
 *
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @returns
 */
export function getTopics () {
  return api.get('/question-topics', { validateStatus: s => s === 200 })
}

/**
 * 举报问题
 *
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {number} questionId
 * @param {string} reason
 * @returns
 */
export function reportQuestion (questionId, reason) {
  const url = `/questions/${questionId}/reports`
  return api.post(url, { reason }, { validateStatus: s => s === 201 })
}

/**
 * 设置问题悬赏
 *
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {number} questionId
 * @param {Object} params
 * @param {number} params.amount
 * @returns
 */
export function setQuestionReward (questionId, params) {
  const url = `/currency-questions/${questionId}/amount`
  return api.patch(url, params, { validateStatus: s => s === 204 })
}

/**
 * 获取问题评论
 *
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {number} questionId
 * @param {Object} params
 * @param {number} [params.limit=20]
 * @param {number} [params.after=0]
 * @returns
 */
export function getQuestionComments (questionId, params) {
  const url = `/questions/${questionId}/comments`
  return api.get(url, { params, validateStatus: s => s === 200 })
}

/**
 * 评论问题
 *
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {number} questionId
 * @param {Object} params
 * @param {string} params.body
 * @param {number} params.reply_user
 * @returns
 */
export function postQuestionComment (questionId, params) {
  const url = `/questions/${questionId}/comments`
  return api.post(url, params, { validateStatus: s => s === 201 })
}

/**
 * 删除问题评论
 *
 * @author mutoe <mutoe@foxmail.com>
 * @export
 * @param {number} questionId
 * @param {number} commentId
 * @returns
 */
export function deleteQuestionComment (questionId, commentId) {
  const url = `/questions/${questionId}/comments/${commentId}`
  return api.delete(url, { validateStatus: s => s === 204 })
}

/**
 * 保存问题草稿
 * @param {*} params
 * @returns
 */
export function postQuestionDraft(id, params) {
  if (id > 0) {
    return api.put(`/drafts/questions/${id}`, {...params, from: 'H5'}, { validateStatus: s => s === 204 })
  }
  return api.post('/drafts/questions', {...params, from: 'H5'}, { validateStatus: s => s === 204 })
}

/**
 * 获取问题草稿
 * @param {*} id
 * @returns
 */
export function fetchDraft (id) {
  return api.get(`/drafts/questions/${id}`, { validateStatus: s => s === 200 })
}
