<template>
  <div class="profile-questions">
    <div class="profile-question-nav">
      <div
        v-for="({ label, type },index) in navs"
        :key="`profile-question-nav-${index}`"
        :class="{active: curType === type}"
        class="profile-question-nav-item"
        @click="curType = type"
      >
        {{ label }}
      </div>
    </div>
  <div>

  <div class="main">
    <JoLoadMore
      ref="loadmore"
      :auto-load="false"
      @onRefresh="onRefresh"
      @onLoadMore="onLoadMore"
    >
      <template v-if="curType == 'questions'">
        <QuestionCard
          v-for="question in dataList"
          :key="question.id"
          :question="question"
        />
      </template>
      <template v-else>
        <QuestionTopicCard
          v-for="topic in dataList"
          :key="topic.id"
          :topic="topic"
          type="follow"
          @unfollow="handleUnfollow"
        />
      </template>
    </JoLoadMore>
  </div>
    </div>
  </div>
</template>

<script>
  import QuestionCard from '@/page/question/components/QuestionCard.vue'
  import QuestionTopicCard from '@/page/question/components/QuestionTopicCard.vue'
  import { mapState } from 'vuex'
  import { limit } from '@/api'
  import { unfollowTopic } from '@/api/question/topics'

  export default {
    name: 'ProfileQuestionFollows',
    components: {
      QuestionCard,
      QuestionTopicCard
    },
    data () {
      return {
        curType: 'questions',
        navs: [
          {
            type: 'questions',
            label: this.$t('profile.question.follows.questions'),
          },
          {
            type: 'topics',
            label: this.$t('profile.question.follows.topics'),
          },
        ],

        dataList: [],
      }
    },
    computed: {
      ...mapState({
        user: state => state.CURRENTUSER,
      }),
      after () {
        const len = this.dataList.length
        return len > 0 ? this.dataList[len - 1].id : 0
      },
      url () {
        return this.curType == 'questions' ? 'user/question-watches' : 'user/question-topics'
      },
      params () {
        const len = this.dataList.length
        if (this.curType == 'questions') {
          return {
            limit: limit,
            after: len > 0 ? this.dataList[len - 1].id : 0
          }
        }
        return {
          limit: limit,
          type: 'follow',
          offset: len
        }
      }
    },
    watch: {
      curType () {
        this.$refs.loadmore.beforeRefresh()
      },
    },
    mounted () {
      this.$refs.loadmore.beforeRefresh()
    },

    methods: {
      onRefresh () {
        const params = { limit: limit, after: 0, type: this.curType }
        this.dataList = []
        this.$http
          .get(this.url, { params: params })
          .then(({ data = [] }) => {
            this.dataList = data
            this.$refs.loadmore.afterRefresh(data.length < limit)
          })

        
      },
      onLoadMore () {
        const params = { limit: limit, after: this.after, type: this.curType }
        this.$http
          .get(this.url, { params: params })
          .then(({ data = [] }) => {
            this.dataList = [...this.dataList, ...data]
            this.$refs.loadmore.afterLoadMore(data.length < limit)
          })
      },
      handleUnfollow (topic) {
        unfollowTopic(topic.id)
          .then(() => {
            this.dataList = this.dataList.filter(item => item.id == topic.id)
          })
          .catch(({ response: { data } = {} }) => {
            this.$Message.error(data)
          })
      },
    },
  }
</script>
<style lang='less'>
.profile-groups .c-group-item {
  border-bottom: 1px solid #ededed
}
</style>
