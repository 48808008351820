<template>
  <div class="p-profile-collection-answers">
    <JoLoadMore
      ref="loadmore"
      :auto-load="false"
      @onRefresh="onRefresh"
      @onLoadMore="onLoadMore"
    >
      <ul>
        <QuestionAnswerCard
          v-for="answer in answerList"
          :key="answer.id"
          :answer="answer"
          :allow-adopt="false"
          :no-user="true"
        />
      </ul>
    </JoLoadMore>
  </div>
</template>

<script>
  import * as api from '@/api/question/answer'
  import { limit } from '@/api'
  import { getLastField } from '@/util'
  import QuestionAnswerCard from '@/page/question/components/QuestionAnswerCard.vue'

  export default {
    name: 'ProfileCollectionAnswer',
    components: { QuestionAnswerCard },
    data () {
      return {
        answerList: [],
      }
    },
    mounted () {
      this.$refs.loadmore.beforeRefresh()
    },
    methods: {
      onRefresh () {
        api.getCollectionAnswers().then(({ data }) => {
          this.answerList = data.map(item => item.collectible)
          this.$refs.loadmore.afterRefresh(data.length < limit)
        })
      },
      onLoadMore () {
        const after = getLastField(this.answerList)
        api.getCollectionAnswers({ after }).then(({ data }) => {
          this.answerList = [...this.answerList, ...data.map(item => item.collectible)]
          this.$refs.loadmore.afterLoadMore(data.length < limit)
        })
      },
    },
  }
</script>

<style lang="less" scoped>
.p-profile-collection-posts {
  .post-item {
    margin-bottom: 10px;
  }
}
</style>
