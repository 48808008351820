<template>
  <div class="c-question-topic-card">
    <RouterLink
      :src="avatar"
      :to="`/question-topics/${topic.id}`"
      tag="img"
      class="topic-image"
    />
    <RouterLink
      :to="`/question-topics/${topic.id}`"
      tag="a"
      class="title"
    >
      <div class="topic m-text-cut">{{ topic.name }}</div>
      <div class="label">
        <span>{{ topic.follows_count }}</span> {{ $t('follow.name') }}
        ·
        <span>{{ topic.questions_count }}</span> {{ $t('question.name') }}
      </div>
    </RouterLink>
    <button
      v-if="topic.has_follow || type === 'follow'"
      class="follow active"
      @click="handleUnfollow(topic)"
    >
      <svg class="m-style-svg m-svg-small">
        <use xlink:href="#icon-yes" />
      </svg>
      {{ $t('follow.already') }}
    </button>
    <button
      v-else
      class="follow"
      @click="handleFollow(topic)"
    >
      <svg class="m-style-svg m-svg-small">
        <use xlink:href="#icon-plus" />
      </svg>
      {{ $t('follow.name') }}
    </button>
  </div>
</template>

<script>
  export default {
    name: 'QuestionTopicCard',
    props: {
      topic: { type: Object, required: true },
      type: { type: String, default: 'hot' },
    },
    computed: {
      avatar () {
        const avatar = this.topic.avatar || {}
        return avatar.url || null
      },
    },
    methods: {
      handleUnfollow (topic) {
        this.$emit('unfollow', topic)
      },
      handleFollow (topic) {
        this.$emit('follow', topic)
      },
    },
  }
</script>

<style lang="less" scoped>
.c-question-topic-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 175px;
  padding: 28px;
  background-color: #fff;
  border-bottom: solid 1px #d7d8d8; /* no */

  .topic-image {
    flex: none;
    width: 120px;
    height: 120px;
    margin-right: 28px;
    border: none;
    background-color: #d7d8d8;
  }

  .title {
    flex: auto;
    min-width: 0;

    .topic {
      font-size: 32px;
      font-weight: normal;
      font-stretch: normal;
      color: #333;
    }

    .label {
      font-size: 28px;
      font-weight: normal;
      font-stretch: normal;
      color: #666;
      margin-top: 16px;

      > span {
        color: @primary;
      }
    }
  }

  .follow {
    flex: none;
    height: 50px;
    width: 6em;
    padding: 0;
    background-color: #fff;
    border-radius: 8px;
    border: solid 2px @primary;
    color: @primary;
    outline: none;

    .m-svg-small {
      width: 28px;
      height: 28px;
    }

    &.active {
      color: #ccc;
      border: solid 2px #ccc;
    }
  }
}
</style>
